import React, { createRef } from 'react'
import './AppHeader.scss'

const AppHeader: React.FC = () => {

  const burgerMenuButton = createRef<HTMLButtonElement>();
  const menu = createRef<HTMLDivElement>();

  const toggleMenu = () => {
    burgerMenuButton.current?.classList.toggle('is-active');
    menu.current?.classList.toggle('is-active');
  }

  return (
      <header className="AppHeader">
        <nav className="navbar is-fixed-top has-shadow" aria-label="Hauptnavigation">
          <div className="container">
            <div className="navbar-brand">
              <a className="navbar-item" href="/">
                <img src="assets/LOGO_FREYRAUM_Schriftzug.svg" alt="Zurück zur Startseite" height={ 50 } width={150} />
              </a>

              <button
                  ref={burgerMenuButton}
                  role="button"
                  className="navbar-burger"
                  aria-label="Hauptmenü"
                  aria-expanded="false"
                  onClick={toggleMenu}
              >
                <span aria-hidden="true"/>
                <span aria-hidden="true"/>
                <span aria-hidden="true"/>
              </button>
            </div>

            <div ref={menu} className="navbar-menu">
              <div className="navbar-start">
                <a className="navbar-item" href="#trainingskonzept">Trainingskonzept</a>
                <a className="navbar-item" href="#trainerinnen">Unsere Trainerinnen</a>
                <a className="navbar-item" href="#kursplan">Kursplan</a>
                <a className="navbar-item" href="#kontakt">Kontakt</a>
              </div>

              <div className="navbar-end">
                <div className="navbar-item">
                  <div className="buttons">
                    <a className="button is-primary" href="/app">
                      <span className="icon-text">
                        <span className="icon">
                          <i className="fas fa-sign-in-alt" aria-hidden="true" />
                        </span>
                        <span>Login</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
  )
}

export default AppHeader
