import React from 'react'
import './Memberships.scss'
import classNames from 'classnames'

interface MembershipOption {
  label: string
  price: number
  isMonthly: boolean
  features: any
  actionLabel: string
  isFavorite?: boolean
}

const membershipOptions: MembershipOption[] = [
  {
    label: '10er-Karte',
    price: 90,
    isMonthly: false,
    features: {
      entries: '10 Eintritte',
      duration: '1 Jahr gültig',
      allCourses: true,
      openGym: true,
      online: true,
    },
    actionLabel: '10er-Karte buchen',
  },
  {
    label: 'MOVE',
    price: 60,
    isMonthly: true,
    features: {
      entries: '8 Eintritte / Monat',
      duration: 'je 3 Monate',
      allCourses: true,
      openGym: true,
      online: true,
    },
    actionLabel: 'Jetzt Probetraining vereinbaren',
    isFavorite: true,
  },
  {
    label: 'JUMP',
    price: 70,
    isMonthly: true,
    features: {
      entries: '12 Eintritte / Monat',
      duration: 'je 3 Monate',
      allCourses: true,
      openGym: true,
      online: true,
    },
    actionLabel: 'Jetzt Probetraining vereinbaren',
    isFavorite: false,
  },
  {
    label: 'NEXT LEVEL',
    price: 80,
    isMonthly: true,
    features: {
      entries: 'So viel du willst',
      duration: 'je 3 Monate',
      allCourses: true,
      openGym: true,
      online: true,
    },
    actionLabel: 'Jetzt Probetraining vereinbaren',
    isFavorite: false,
  },
  {
    label: 'U18',
    price: 45,
    isMonthly: true,
    features: {
      entries: 'So viel du willst',
      duration: 'monatlich kündbar',
      allCourses: true,
      openGym: false,
      online: false,
      additionalInfo: '*',
    },
    actionLabel: 'Jetzt Probetraining vereinbaren',
    isFavorite: false,
  },
]


const Memberships: React.FC = () => {

  return (
      <section id="memberships" className="Memberships">
        <div className="hero is-medium is-primary my-6">
          <div className="container p-0">
            <div className="hero-body">
              <h2 className="title">Mitgliedschaften</h2>
              <h3 className="subtitle">Für jeden etwas dabei</h3>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="pricing-table is-comparative">
            <div className="pricing-plan is-features">
              <div className="plan-header">&nbsp;</div>
              <div className="plan-price">
                <span className="plan-price-amount">&nbsp;</span>
                <span className="price-interval">&nbsp;</span>
              </div>
              <div className="plan-items">
                <div className="plan-item">Eintritte</div>
                <div className="plan-item">Komplettes Kursprogramm</div>
                <div className="plan-item">Open Gym</div>
                <div className="plan-item">Online Kurse</div>
                <div className="plan-item">Laufzeit</div>
              </div>
              <div className="plan-footer">

              </div>
            </div>
            {membershipOptions.map(option => (
                <div className={classNames('pricing-plan', { 'is-active': option.isFavorite })} key={option.label}>
                  <div className="plan-header">{option.label}</div>
                  <div className="plan-price">
                    <span className="plan-price-amount">
                      <div className="price-container">
                        {option.price}
                        {option.features.additionalInfo && <span className="additional-info">*</span>}
                      </div>
                      <span className="price-interval">{option.isMonthly ? '€ monatlich' : '€ einmalig' }</span>
                    </span>
                  </div>
                  <div className="plan-items">
                    <div className="plan-item" data-feature="Eintritte">{option.features.entries}</div>
                    <div className="plan-item" data-feature="Komplettes Kursprogramm">
                      {option.features.allCourses ? (
                          <span className="icon is-small">
                            <i className="fas fa-check check-success" aria-label="Inklusive" />
                          </span>
                      ) : (
                          <span className="icon is-small">
                            <i className="fas fa-minus" aria-label="Nicht enthalten" />
                          </span>
                      )}
                    </div>
                    <div className="plan-item" data-feature="Open Gym">
                      {option.features.openGym ? (
                          <span className="icon is-small">
                            <i className="fas fa-check check-success" aria-label="Inklusive" />
                          </span>
                      ) : (
                          <span className="icon is-small">
                            <i className="fas fa-minus" aria-label="Nicht enthalten" />
                          </span>
                      )}
                    </div>
                    <div className="plan-item" data-feature="Online Kurse">
                      {option.features.online ? (
                          <span className="icon is-small">
                            <i className="fas fa-check check-success" aria-label="Inklusive" />
                          </span>
                      ) : (
                          <span className="icon is-small">
                            <i className="fas fa-minus" aria-label="Nicht enthalten" />
                          </span>
                      )}
                    </div>
                    <div className="plan-item" data-feature="Laufzeit">{option.features.duration}</div>
                  </div>
                  <div className="plan-footer">
                    <a
                        className="button is-fullwidth"
                        href={"mailto:freyraum@freya.fitness?subject=Interesse an " + option.label}
                        target="_blank"
                        rel="noopener"
                        title="Kontaktiere uns via E-Mail (öffnet externes E-Mail-Programm)"
                    >
                      {option.isFavorite ? 'Training starten' : 'Auswählen'}
                    </a>
                  </div>
                </div>
            ))}
          </div>
          <div className="mt-5">
            <p>*) Der Preis ist gültig, wenn mindestens ein Elternteil bereits Mitglied im FREYRAUM ist. Wenn kein Elternteil Mitglied im FREYRAUM ist, erhöht sich der Beitrag der U18-Mitgliedschaft auf 55€ im Monat.</p>
          </div>
        </div>
      </section>
  )
}

export default Memberships
