import React from 'react'
import './Hero.scss'

const Hero: React.FC = () => {

  return (
      <section id="hero" className="Hero">
        <div className="hero-section">
          <div className="hero-background" />
          <div className="container">
            <div className="home-claim">
              <div className="home-claim-title">
                <h1>Willkommen im FREYRAUM</h1>
                <div className="home-claim-new-location">
                  <span className="is-size-6 has-text-weight-bold">hier findet ihr uns:</span>
                  <span className="is-flex is-flex-direction-column is-align-items-center">
                    <span className="is-size-7">Huskoppel 3</span>
                    <span className="is-size-7">Salzhausen/Oelsdorf</span>
                  </span>
                  <span className="is-size-7 has-text-weight-bold home-claim-new-location-area">Training auf 350m<sup>2</sup></span>
                </div>
              </div>
              <div className="home-claim-subtitle">
                <h2>Deine Adresse für Sport in und um Salzhausen</h2>
                <a role="button" className="button is-primary mt-3"
                   href="mailto:freyraum@freya.fitness?subject=Probetraining im FREYRAUM"
                   title="Sende eine E-Mail und frage nach einem Probetraining">
                   <span className="icon-text">
                      <span className="icon">
                        <i className="fas fa-envelope" aria-hidden="true" />
                      </span>
                      <span>Probetraining vereinbaren</span>
                    </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Hero
