import React from 'react'
import classNames from 'classnames'

interface InputProps {
  id: string
  label: string
  placeholder?: string
  value?: string
  onChange?: (next: string) => void
  onBlur?: (next: string) => void
  isValid?: boolean
  error?: string
  mandatory?: boolean
}

export const Textarea: React.FC<InputProps> = ({
                                                 id,
                                                 label,
                                                 placeholder,
                                                 value,
                                                 onChange,
                                                 onBlur,
                                                 isValid,
                                                 error,
                                                 mandatory
                                               }: InputProps) => {

  return (
      <div className="field">
        <label className="label is-flex" htmlFor={id}>
          {label}
          {mandatory && (
              <span className="icon is-small has-text-info ml-1">
              <i className="fas fa-asterisk fa-2xs" />
            </span>
          )}
        </label>
        <div className="control has-icons-right">
          <textarea
              id={id}
              className={classNames('textarea', {
                'is-success': isValid,
                'is-danger': isValid === false,
              })}
              placeholder={placeholder ?? label}
              value={value}
              onChange={e => onChange?.(e.target.value)}
              onBlur={e => onBlur?.(e.target.value)}
              aria-required={mandatory}
          />
          {isValid && (
              <span className="icon is-small is-right">
              <i className="fas fa-check"></i>
            </span>
          )}
        </div>
        {isValid === false && !!error && (
            <p className="help is-danger">{error}</p>
        )}
      </div>
  )
}
