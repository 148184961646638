import React from 'react'
import './Home.scss'
import Memberships from './Memberships/Memberships'
import CoursePlan from './CoursePlan/CoursePlan'
import Concept from './Concept/Concept'
import Trainer from './Trainer/Trainer'
import Hero from './Hero/Hero'
import { Contact } from './Contact/Contact'

const Home: React.FC = () => {
  return <div className="Home">
    <Hero />
    <Concept />
    <Memberships />
    <Trainer />
    <CoursePlan />
    <Contact />
  </div>
}

export default Home
