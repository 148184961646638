import React, { useState } from 'react';
import { Input } from '../../../components/Input/Input';
import { Textarea } from '../../../components/Textarea/Textarea';
import { FormValue } from '../../../utils/form-value.interface';
import { post } from '../../../utils/http';
import { isEmail, isTelephone, notEmpty } from '../../../utils/validation/validators';


interface ContactData {
  firstname: FormValue<string>;
  lastname: FormValue<string>;
  email: FormValue<string>;
  telephone: FormValue<string>;
  subject: FormValue<string>;
  message: FormValue<string>;
}

const initialState: ContactData = {
  firstname: {
    value: '',
    validator: notEmpty,
  },
  lastname: {
    value: '',
    validator: notEmpty,
  },
  email: {
    value: '',
    validator: isEmail,
  },
  telephone: {
    value: '',
    validator: isTelephone,
  },
  subject: {
    value: '',
    validator: notEmpty,
  },
  message: {
    value: '',
    validator: notEmpty,
  },
};

export const Contact: React.FC = () => {

  const [contactData, setContactData] = useState<ContactData>(initialState);
  const [showNotification, setShowNotification] = useState(false);

  const update = (fieldname: keyof ContactData) => (value: string) => {
    setContactData(current => ({
      ...current,
      [fieldname]: {
        ...current[fieldname],
        value,
      },
    }));
  };

  const validate = (fieldname: keyof ContactData): boolean => {
    const isValid = contactData[fieldname].validator(contactData[fieldname].value);
    setContactData(current => ({
      ...current,
      [fieldname]: {
        ...current[fieldname],
        isValid,
      },
    }));
    return isValid;
  };

  const submitContactRequest = () => {
    let anyInvalid = false;
    Object.keys(contactData).forEach((key) => {
      const fieldname = key as keyof ContactData;
      if (!validate(fieldname) && !anyInvalid) {
        anyInvalid = true;
        document.getElementById(fieldname)?.focus();
      }
    });

    if (!anyInvalid) {
      console.warn('SEND MAIL', contactData);
      post('/contact', {
        firstname: contactData.firstname.value,
        lastname: contactData.lastname.value,
        email: contactData.email.value,
        telephone: contactData.telephone.value,
        subject: contactData.subject.value,
        message: contactData.message.value,
      }).then(() => {
        clearInput();
        showSuccessMessage();
      });
    }
  };

  const clearInput = () => setContactData(initialState);

  const showSuccessMessage = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  };

  return (
      <section id="kontakt" className="mb-6">
        <div className="hero is-medium is-primary my-6">
          <div className="container p-0">
            <div className="hero-body">
              <h2 className="title">Kontakt</h2>
              <h3 className="subtitle">Hast du noch Fragen oder möchtest gleich mit dem Training beginnen?</h3>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            <div className="columns is-flex-wrap-wrap">
              <div className="column is-half">
                <Input
                    id="firstname"
                    label="Vorname"
                    value={ contactData.firstname.value }
                    onChange={ update('firstname') }
                    onBlur={ () => validate('firstname') }
                    mandatory
                    isValid={ contactData.firstname.isValid }
                    error="Bitte gib Deinen vollständigen Namen an"/>
              </div>
              <div className="column is-half">
                <Input
                    id="lastname"
                    label="Nachname"
                    value={ contactData.lastname.value }
                    onChange={ update('lastname') }
                    onBlur={ () => validate('lastname') }
                    mandatory
                    isValid={ contactData.lastname.isValid }
                    error="Bitte gib Deinen vollständigen Namen an"/>
              </div>
              <div className="column is-half">
                <Input
                    id="email"
                    label="E-Mail"
                    type="email"
                    value={ contactData.email.value }
                    onChange={ update('email') }
                    onBlur={ () => validate('email') }
                    mandatory
                    isValid={ contactData.email.isValid }
                    error="Bitte gib eine gültige E-Mail-Adresse an, damit wir dich erreichen können"/>
              </div>
              <div className="column is-half">
                <Input
                    id="telephone"
                    label="Telefon"
                    value={ contactData.telephone.value }
                    onChange={ update('telephone') }
                    onBlur={ () => validate('telephone') }
                    mandatory
                    isValid={ contactData.telephone.isValid }
                    error="Bitte gib eine gültige Telefonnummer an, damit wir dich erreichen können"/>
              </div>
              <div className="column is-full">
                <Input
                    id="subject"
                    label="Betreff"
                    value={ contactData.subject.value }
                    onChange={ update('subject') }
                    onBlur={ () => validate('subject') }
                    mandatory
                    isValid={ contactData.subject.isValid }
                    error="Bitte hilf uns mit einem kurzen Betreff Deiner Nachricht. Z.B.: Probetraining"/>
              </div>
              <div className="column is-full">
                <Textarea
                    id="message"
                    label="Nachricht"
                    value={ contactData.message.value }
                    onChange={ update('message') }
                    onBlur={ () => validate('message') }
                    mandatory
                    isValid={ contactData.message.isValid }
                    error="Bitte beschreibe kurz, wie wir Dir helfen können"/>
              </div>
            </div>
            {
                showNotification && (
                    <div className="notification is-success">
                      Deine Anfrage wurde verschickt
                    </div>
                )
            }
            <button
                className="button is-primary mt-3"
                title="Sende Deine Anfrage an Deinen Freyraum"
                onClick={ submitContactRequest }
            >
              Kontaktanfrage absenden
            </button>
          </div>
        </div>
      </section>
  );

};
