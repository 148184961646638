import { color, Course } from '../../../model/Course'
import React from 'react'
import classNames from 'classnames'
import { shadeRgbColor } from '../../../utils/color'

interface CoursePlanCardProps {
  course: Course
  parallelCourses: number
  positionParallelCourses: number
  isHighlighted: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
}

export const CoursePlanCard: React.FC<CoursePlanCardProps> = (
    {
      course,
      parallelCourses,
      positionParallelCourses,
      isHighlighted,
      onMouseEnter,
      onMouseLeave,
    }: CoursePlanCardProps) => {

  const courseTypeColor = color(course.courseType)

  return (
      <div
          className={classNames("course-tile my-2", { highlighted: isHighlighted })}
          style={ {
            backgroundColor: isHighlighted ? shadeRgbColor(courseTypeColor, 0.9) : undefined,
            width: parallelCourses > 1 ? `calc(${ 100 / parallelCourses }% - 5px)` : '100%',
            marginLeft: positionParallelCourses === 0 ? 0 : 5,
            marginRight: positionParallelCourses === parallelCourses - 1 ? 0 : 5,
            outlineColor: courseTypeColor
          } }
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
      >
        <span className="course-type-indicator" style={{
          backgroundColor: courseTypeColor,
        }} />
        <p className="course-title">{ course.courseType }</p>
        <p className="times">
          <span>{ course.start }</span>
          { ' - ' }
          <span>{ course.end }</span>
        </p>
      </div>
  )
}
