export enum CourseType {
  BASE_POWER = 'BASE.POWER',
  BASE_TEAM = 'BASE.TEAM',
  BEST_AGERS = 'Best Agers',
  FUN_BASE = 'FUN.BASE',
  FUN_POWER = 'FUN.POWER',
  FUN_TEAM = 'FUN.TEAM',
  MOBILITY_CORE = 'MOBILITY & CORE',
  MOBILITY_CORE_ONLINE = 'MOBILITY & CORE ONLINE',
  FIT_MOMS = 'FIT.MOMS',
  YOUTH_TEAM = 'YOUTH.TEAM',
  KRAFT_UND_TECHNIK = 'Kraft & Technik',
  YOGA = 'Yoga',
  LAUFKURS_ANFAENGER = 'Laufkurs Anfänger',
  CARDIO_WETTKAMPF = 'Cardio | Wettkampf',
  CARDIO = 'CARDIO KURS',
  GYMNASTIK_CORE = 'GYMNASTIK & CORE',
}

export const color = (type: CourseType): string => {
  switch (type) {
    case CourseType.BASE_POWER:
      return '#FF9800';
    case CourseType.BASE_TEAM:
      return '#0250F2'
    case CourseType.BEST_AGERS:
      return '#FFC107'
    case CourseType.FUN_BASE:
      return '#03A9F4'
    case CourseType.FUN_POWER:
      return '#FF9800'
    case CourseType.FUN_TEAM:
      return '#00E676'
    case CourseType.MOBILITY_CORE:
      return '#79a615'
    case CourseType.MOBILITY_CORE_ONLINE:
      return '#79a615'
    case CourseType.FIT_MOMS:
      return '#673AB7'
    case CourseType.YOUTH_TEAM:
      return '#00E676';
    case CourseType.KRAFT_UND_TECHNIK:
      return '#FF5722'
    case CourseType.YOGA:
      return '#bdff87';
    case CourseType.LAUFKURS_ANFAENGER:
      return '#009688'
    case CourseType.CARDIO_WETTKAMPF:
      return '#000000';
    case CourseType.CARDIO:
      return '#87FFDF';
    case CourseType.GYMNASTIK_CORE:
      return '#4511ff';

  }

  return ''
}

export interface Course {
  courseType: CourseType
  start: string
  end: string
  location?: string
}
