import React from 'react'
import './Trainer.scss'

const Trainer: React.FC = () => {

  return (
      <section id="trainerinnen" className="Trainer">
        <div className="hero is-medium is-primary my-6">
          <div className="container p-0">
            <div className="hero-body">
              <h2 className="title">Unsere Trainerinnen</h2>
              <h3 className="subtitle">Wer sind die Gesichter hinter dem FREYRAUM?</h3>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            <div className="tile is-ancestor is-justify-content-center is-flex-wrap-wrap">
              <div className="tile is-parent is-4">
                <div className="tile is-child">
                  <div className="box py-6">
                    <div className="title has-text-centered">Finja Schulenburg</div>
                    <div className="subtitle has-text-centered">Coach</div>
                    <figure className="image is-square mb-6">
                      <img src="assets/finja.jpg" alt="" />
                    </figure>
                    <div>
                      <p className="block">
                        Als studierte Fitnessökonomin kam Finja im August 2020 als Trainerin zu uns im FREYRAUM. Mit
                        viel positiver Energie unterstützt sie seitdem die Mitglieder beim Erreichen Ihrer Zielen.
                      </p>
                      <p className="block">
                        Zusätzlich zum sportlichen Teil ist Finja auch Ansprechpartnerin bei dem Thema Ernährung.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tile is-parent is-4">
                <div className="tile is-child">
                  <div className="box py-6">
                    <div className="title has-text-centered">Swantje Thalheim</div>
                    <div className="subtitle has-text-centered">Coach</div>
                    <figure className="image is-square mb-6">
                      <img src="assets/swantje-t.jpg" alt="" />
                    </figure>
                    <div>
                      <p className="block">
                        Swantje ist seit fünf Jahren im Freyraum dabei. Der Freyraum hat ihr dabei geholfen, ihre
                        sportlichen Fähigkeiten zu verbessern und sich weiter zu entwickeln.
                      </p>
                      <p className="block">
                        Anfang 2023 hat sie die Lizenz zur Athletiktrainerin erworben und unterstützt Freya und Finja
                        nun beim coachen im Freyraum.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tile is-parent is-4">
                <div className="tile is-child">
                  <div className="box py-6">
                    <div className="title has-text-centered">Freya Petersen</div>
                    <div className="subtitle has-text-centered">Owner & Coach</div>
                    <figure className="image is-square mb-6">
                      <img src="assets/freya.jpg" alt="" />
                    </figure>
                    <div>
                      <p className="block">
                        Mit der Eröffnung des FREYRAUMs im Sommer 2018 erfüllte Freya sich einen großen Traum, der sich
                        seit Jahren in Ihren Kopf gesetzt hat.
                      </p>
                      <p className="block">
                        Der Weg zur Selbstständigkeit führte sie nach dem Studium über einen 2-jährigen Aufenthalt in
                        Amerika zurück ins heimatliche Toppenstedt. Dort wurden Scheunen, Gärten, Carports,
                        Schützenhallen, alle möglichen Räume genutzt, um das funktionelle Training im Ort zu etablieren.
                      </p>
                      <p className="block">
                        Durch ihre positive, offene und fröhliche Art ist der FREYRAUM gewachsen und lebt weiterhin
                        durch ihren Ideenreichtum.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tile is-parent is-4">
                <div className="tile is-child">
                  <div className="box py-6">
                    <div className="title has-text-centered">Bärbel</div>
                    <div className="subtitle has-text-centered">Well-Being Managerin</div>
                    <figure className="image is-square mb-6">
                      <img src="assets/baerbel.png" alt="" />
                    </figure>
                    <div>
                      <p className="block">
                        Freyas rechte Hand oder auch der Hund für die gute Laune. An ihr kommt keiner vorbei, ohne
                        einmal abgeschnüffelt zu werden. Auch Taschenkontrollen sind jederzeit möglich. Denn bei Bärbel
                        bleibt kein Leckerlie unentdeckt.
                      </p>
                      <p className="block">
                        Es bleibt fraglich, ob der eine oder andere wirklich zum Sport
                        kommt oder doch nur zum Hundstreicheln da ist …
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
  )
}

export default Trainer
