import React, { useState } from 'react'
import './CoursePlan.scss'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Course, CourseType } from '../../../model/Course'
import { CoursePlanCard } from './CoursePlanCard'
import { CourseTypeLegend } from './CourseTypeLegend'

dayjs.extend(customParseFormat)

interface Weekday {
  label: string
  courses: Course[]
}

const plan: Weekday[] = [
  {
    label: 'Montag',
    courses: [
      {
        courseType: CourseType.MOBILITY_CORE_ONLINE,
        start: '06:25',
        end: '06:55',
      },
      {
        courseType: CourseType.BASE_POWER,
        start: '07:00',
        end: '08:00',
      },
      {
        courseType: CourseType.FUN_BASE,
        start: '09:00',
        end: '10:00',
      },
      {
        courseType: CourseType.FUN_POWER,
        start: '09:00',
        end: '10:00',
      },
      {
        courseType: CourseType.FIT_MOMS,
        start: '10:00',
        end: '10:45',
      },
      {
        courseType: CourseType.YOUTH_TEAM,
        start: '15:30',
        end: '16:30',
      },
      {
        courseType: CourseType.FUN_BASE,
        start: '16:30',
        end: '17:30',
      },
      {
        courseType: CourseType.FUN_POWER,
        start: '16:30',
        end: '17:30',
      },
      {
        courseType: CourseType.FUN_BASE,
        start: '17:30',
        end: '18:30',
      },
      {
        courseType: CourseType.KRAFT_UND_TECHNIK,
        start: '17:30',
        end: '18:30',
      },
      {
        courseType: CourseType.FUN_BASE,
        start: '18:30',
        end: '19:30',
      },
      {
        courseType: CourseType.FUN_TEAM,
        start: '18:30',
        end: '19:30',
      },
      {
        courseType: CourseType.YOGA,
        start: '19:30',
        end: '20:30',
      },
      {
        courseType: CourseType.BASE_POWER,
        start: '20:30',
        end: '21:30',
      },
    ],
  },
  {
    label: 'Dienstag',
    courses: [
      {
        courseType: CourseType.KRAFT_UND_TECHNIK,
        start: '07:00',
        end: '08:00',
      },
      {
        courseType: CourseType.BASE_POWER,
        start: '09:00',
        end: '10:00',
      },
      {
        courseType: CourseType.BEST_AGERS,
        start: '10:00',
        end: '11:00',
      },
      {
        courseType: CourseType.MOBILITY_CORE,
        start: '12:00',
        end: '12:30',
      },
      {
        courseType: CourseType.BASE_POWER,
        start: '16:30',
        end: '17:30',
      },
      {
        courseType: CourseType.FUN_POWER,
        start: '17:30',
        end: '18:30',
      },
      {
        courseType: CourseType.FUN_BASE,
        start: '17:30',
        end: '18:30',
      },
      {
        courseType: CourseType.FUN_BASE,
        start: '18:30',
        end: '19:30',
      },
      {
        courseType: CourseType.KRAFT_UND_TECHNIK,
        start: '18:30',
        end: '19:30',
      },
      {
        courseType: CourseType.FUN_TEAM,
        start: '19:30',
        end: '20:30',
      },
    ],
  },
  {
    label: 'Mittwoch',
    courses: [
      {
        courseType: CourseType.CARDIO,
        start: '06:00',
        end: '07:00',
      },
      {
        courseType: CourseType.MOBILITY_CORE,
        start: '06:25',
        end: '06:55',
      },
      {
        courseType: CourseType.BASE_POWER,
        start: '07:00',
        end: '08:00',
      },
      {
        courseType: CourseType.BASE_POWER,
        start: '09:00',
        end: '10:00',
      },
      {
        courseType: CourseType.FIT_MOMS,
        start: '10:00',
        end: '11:00',
      },
      {
        courseType: CourseType.FUN_BASE,
        start: '16:30',
        end: '17:30',
      },
      {
        courseType: CourseType.FUN_POWER,
        start: '16:30',
        end: '17:30',
      },
      {
        courseType: CourseType.YOGA,
        start: '17:30',
        end: '18:30',
      },
      {
        courseType: CourseType.GYMNASTIK_CORE,
        start: '17:30',
        end: '18:30',
      },
      {
        courseType: CourseType.FUN_BASE,
        start: '18:30',
        end: '19:30',
      },
      {
        courseType: CourseType.CARDIO,
        start: '18:30',
        end: '19:30',
      },
      {
        courseType: CourseType.FUN_TEAM,
        start: '19:30',
        end: '20:30',
      },
    ],
  },
  {
    label: 'Donnerstag',
    courses: [
      {
        courseType: CourseType.KRAFT_UND_TECHNIK,
        start: '07:00',
        end: '08:00',
      },
      {
        courseType: CourseType.YOGA,
        start: '09:00',
        end: '10:00',
      },
      {
        courseType: CourseType.CARDIO,
        start: '10:00',
        end: '11:00',
      },
      {
        courseType: CourseType.FUN_BASE,
        start: '16:30',
        end: '17:30',
      },
      {
        courseType: CourseType.KRAFT_UND_TECHNIK,
        start: '17:30',
        end: '18:30',
      },
      {
        courseType: CourseType.FUN_BASE,
        start: '18:30',
        end: '19:30',
      },
    ],
  },
  {
    label: 'Freitag',
    courses: [
      {
        courseType: CourseType.CARDIO,
        start: '06:00',
        end: '07:00',
      },
      {
        courseType: CourseType.MOBILITY_CORE,
        start: '06:00',
        end: '07:00',
      },
      {
        courseType: CourseType.BASE_POWER,
        start: '07:00',
        end: '08:00',
      },
      {
        courseType: CourseType.FUN_POWER,
        start: '09:00',
        end: '10:00',
      },
      {
        courseType: CourseType.FUN_BASE,
        start: '10:00',
        end: '11:00',
      },
      {
        courseType: CourseType.FIT_MOMS,
        start: '11:00',
        end: '12:00',
      },
      {
        courseType: CourseType.FUN_POWER,
        start: '15:30',
        end: '16:30',
      },
      {
        courseType: CourseType.FUN_BASE,
        start: '16:30',
        end: '17:30',
      },
      {
        courseType: CourseType.CARDIO_WETTKAMPF,
        start: '17:30',
        end: '18:30',
      },
    ],
  },
  {
    label: 'Sonntag',
    courses: [
      {
        courseType: CourseType.MOBILITY_CORE,
        start: '09:15',
        end: '09:45',
      },
      {
        courseType: CourseType.BASE_TEAM,
        start: '10:00',
        end: '11:00',
      },
      {
        courseType: CourseType.FUN_TEAM,
        start: '11:00',
        end: '12:00',
      },
    ],
  },
]

const CoursePlan: React.FC = () => {

  const [highlightedType, setHighlightedType] = useState<CourseType>()

  const renderCourses = (course: Course, index: number, courses: Course[]) => {
    const start = dayjs(course.start, 'HH:mm')
    const end = dayjs(course.end, 'HH:mm')

    const parallelCourses = courses
        .filter(c => dayjs(c.end, 'HH:mm').isAfter(start) && dayjs(c.start, 'HH:mm').isBefore(end))
        .length

    const positionParallelCourses = [...courses].slice(0, index)
        .filter(c => dayjs(c.end, 'HH:mm').isAfter(start) && dayjs(c.start, 'HH:mm').isBefore(end))
        .length

    return (
        <CoursePlanCard
            key={`${course.start}_${course.courseType}`}
            course={course}
            parallelCourses={parallelCourses}
            positionParallelCourses={positionParallelCourses}
            isHighlighted={course.courseType === highlightedType}
            onMouseEnter={() => setHighlightedType(course.courseType)}
            onMouseLeave={() => setHighlightedType(undefined)}
        />
    )
  }

  return (
      <section id="kursplan" className="CoursePlan">
        <div className="hero is-medium is-primary my-6">
          <div className="container p-0">
            <div className="hero-body">
              <h2 className="title">Kursplan</h2>
              <h3 className="subtitle">Sieht Dir unser Angebot an</h3>
            </div>
          </div>
        </div>

        <div>
          <div className="container">
            <p className="block">
              In jedem Kurs werden 10 - 30 verschiedene Übungen trainiert. Das Konzept wird jedes Mal individuell und
              neu für den Tag entwickelt. Wichtig ist hierbei vor allem das Integrieren von funktionellen Übungen, die
              alltagsnah trainiert werden. Dabei werden auch Eigenschaften wie Koordination, Gleichgewicht,
              Beweglichkeit, Kraft und Schnelligkeit einbezogen und je nach Trainingsstand verbessert.
            </p>
          </div>
        </div>

        <div className="container">
          <div className="tile is-ancestor">
            {
              plan.map(day => (
                  <div className="tile is-parent p-1 is-vertical" key={day.label}>
                    <h3 className="box course-day">
                      {day.label}
                    </h3>
                    <div className="full-day-of-working">
                      <div className="am">
                        <h4>vormittags</h4>
                        <div className="courses">
                          {
                            day.courses
                                .filter(course => dayjs(course.start, 'HH:mm').isBefore(dayjs('12:00', 'HH:mm')))
                                .map(renderCourses)
                          }
                        </div>
                      </div>
                      <div className="pm">
                        <h4>nachmittags</h4>
                        <div className="courses">
                          {
                            day.courses
                                .filter(course => dayjs(course.start, 'HH:mm').isAfter(dayjs('12:00', 'HH:mm'))
                                    && dayjs(course.start, 'HH:mm').isBefore(dayjs('18:00', 'HH:mm')))
                                .map(renderCourses)
                          }
                        </div>
                      </div>
                      <div className="night">
                        <h4>abends</h4>
                        <div className="courses">
                          {
                            day.courses
                                .filter(course => dayjs(course.start, 'HH:mm').isAfter(dayjs('18:00', 'HH:mm')))
                                .map(renderCourses)
                          }
                        </div>
                      </div>
                    </div>
                  </div>
              ))
            }
          </div>
        </div>

        <div className="container">
          <CourseTypeLegend courseType={CourseType.FUN_BASE} description="Der Einstieg in die Grundlagen des funktionalen Trainings" />
          <CourseTypeLegend courseType={CourseType.FUN_POWER} description="Die Fortsetzung. Wenn die Grundlagen sitzen, kannst du hier mehr aus dir herausholen. Schweißtreibend." />
          <CourseTypeLegend courseType={CourseType.FUN_TEAM} description="Für Teamwork. Hier kann dich alles erwarten… Aber gemeinsam kommt ihr ans Ziel." />
          <CourseTypeLegend courseType={CourseType.BASE_TEAM} description="Der etwas sanftere Einstieg ins Teamtraining mit den bekannten Base-Übungen." />
          <CourseTypeLegend courseType={CourseType.KRAFT_UND_TECHNIK} description="Man lernt nie aus. Übungen mit der Langhantel – ob Anfänger oder Fortgeschrittene – hier lernt jeder noch etwas dazu." />
          <CourseTypeLegend courseType={CourseType.BASE_POWER} description="Die Kombi für Alle. Base-Übungen und Power-Übungen für gemeinsames Training." />
          <CourseTypeLegend courseType={CourseType.FIT_MOMS} description="Kinder willkommen. Die Grundlagen des funktionellen Trainings mit Rücksicht auf den Beckenboden." />
          <CourseTypeLegend courseType={CourseType.LAUFKURS_ANFAENGER} description="Gemeinsam schaffen wir die ersten Kilometer. Geführtes Training von 0 auf 5km." />
          <CourseTypeLegend courseType={CourseType.GYMNASTIK_CORE} description="Turnstunde. Mit der Rumpfmuskulatur im Fokus könnt ihr hier alle gymnastischen Übungen lernen und verbessern." />
          <CourseTypeLegend courseType={CourseType.BEST_AGERS} description="Funktionelles Training, egal in welchem Alter." />
          <CourseTypeLegend courseType={CourseType.CARDIO} description="Rudern, Ski fahren, Fahrrad fahren, laufen. Mal kurze, mal lange Distanz und für alle Level." />
          <CourseTypeLegend courseType={CourseType.MOBILITY_CORE_ONLINE} description="30 Minuten Beweglichkeit inklusive Bauch- und Rückentraining – und das alles von zu Hause." />
          <CourseTypeLegend courseType={CourseType.YOGA} description="Entspannung. Bewegen, atmen, ankommen." />
          <CourseTypeLegend courseType={CourseType.CARDIO_WETTKAMPF} description="Lasst uns gemeinsam für Wettkämpfe im funktionellen Training fit werden." />
          <CourseTypeLegend courseType={CourseType.YOUTH_TEAM} description="Früh übt sich. Funktionelles Training für den Nachwuchs (10-15 Jahre)." />
        </div>
      </section>
  )
}

export default CoursePlan
