import React from 'react'
import { color, CourseType } from '../../../model/Course'
import './CourseTypeLegend.scss'

interface CourseTypeLegendProps {
  courseType: CourseType
  description: string
}

export const CourseTypeLegend: React.FC<CourseTypeLegendProps> = ({
                                                                    courseType,
                                                                    description
                                                                  }: CourseTypeLegendProps) => {
  const courseTypeColor = color(courseType)
  return (
      <div className="course-type-legend">
        <span className="course-type" style={{ borderColor: courseTypeColor }}>{courseType}</span>
        <span className="course-description">{description}</span>
      </div>
  )
}
