import React from 'react'
import './Concept.scss'

const Concept: React.FC = () => {


  return (
      <section id="trainingskonzept" className="Concept">
        <div className="mt-6">
          <div className="container">
            <p className="block">
              FREYRAUM ist ein in der Region einzigartiges Konzept im Bereich Fitness- und Kurstraining. Ein Raum, in
              dem vor allem der Spaß an Bewegung an erster Stelle steht und ein abwechslungsreiches Trainingsprogramm
              wartet. Jedes Mal anders, jedes Mal neu!
            </p>
            <p className="block">
              Neben dem breiten Kursprogramm können Mitglieder auch zum eigenständigen bzw. freien Training
              vorbeikommen.
            </p>
          </div>
        </div>
        <div className="hero is-medium is-primary my-6">
          <div className="container p-0">
            <div className="hero-body">
              <h2 className="title">Trainingskonzept</h2>
              <h3 className="subtitle">Erfahre wie wir im FREYRAUM Deine Ziele mit Dir zusammen erreichen</h3>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            <div className="tile is-ancestor">
              <div className="tile is-vertical is-8">
                <div className="tile">
                  <div className="tile is-parent is-vertical">
                    <article className="tile is-child notification is-primary">
                      <p className="title">Individuell</p>
                      <div className="content">
                        Das Trainingskonzept beruht vor allem auf einem hohen Maß an Betreuung der einzelnen
                        Trainierenden. Dieses wird in den Kursen, aber auch im Open Gym Bereich sichergestellt.
                      </div>
                    </article>
                    <img className="image mb-5" src="assets/concept_squats.jpg" alt="Training mit der Langhantelstange im FREYRAUM" />
                    <article className="tile is-child box">
                      <p className="title">Ausgebildete Trainer</p>
                      <div className="content">
                        <p>
                          Mit Unterstützung vom Trainerpersonal, welches aus ausgebildeten Fachkräften besteht, kann
                          jeder
                          im FREYRAUM sich und seine Stäken kennenlernen.
                        </p>
                        <a className="button is-fullwidth" href="./#trainerinnen">Unsere Trainerinnen</a>
                      </div>
                    </article>
                  </div>
                  <div className="tile is-parent is-vertical">
                    <article className="tile is-child box">
                      <p className="title">Vielfältiger Kursplan</p>
                      <div className="content">
                        <p>
                          Das vielfältige Fitnessangebot umfasst Kurse des Functional Trainings, Gewichtheben, Tabata-
                          Zirkel, Kettlebelltraining, TRX-Kurse und Athletiktraining für Sportler. Pro Stunde finden bis
                          zu 2 Kurse mit je maximal 12 Teilnehmer:innen und je einer Trainerin statt.
                        </p>
                        <a className="button is-fullwidth" href="./#kursplan">Zum Kursplan</a>
                      </div>
                    </article>
                    <img className="image" src="assets/concept_team.jpg" alt="Die Kursteilnehmer:innen sitzen an der Wand und Freya macht es sich gemütlich" />
                  </div>
                </div>
                <div className="tile is-parent">
                  <article className="tile is-child box">
                    <p className="title">Freies Training</p>
                    <div className="content">
                      Zusätzlich können Trainingspläne für das eigene freie Training erstellt
                      werden.
                    </div>
                  </article>
                </div>
              </div>
              <div className="tile is-parent is-vertical">
                <img className="image mb-5" src="assets/concept_trx.jpg" alt="Trainingseinheit mit TRX-Bändern im FREYRAUM" />
                <article className="tile is-child box">
                  <div className="content">
                    <p className="title">Trainings&shy;angebot</p>
                    <div className="content">
                      <p>
                        Functional Training und Ausdauertraining stehen als Trainingskonzepte mit ganzheitlichen
                        Bewegungen ganz klar im Fokus. Racks und Klimmzugstangen stellen den zentralen Punkt des
                        Sportstudios dar.
                      </p>
                      <p>
                        Langhanteln, Kettlebells, TRX-Bänder, Faszienrollen und sonstige Kleingeräte
                        runden das sportliche Angebot ab und bieten jedem Fitnesssportler die Möglichkeit, seine/ihre
                        individuellen Ziele zu erreichen.
                      </p>
                    </div>
                  </div>
                </article>
                <img className="image" src="assets/concept_plank.jpg" alt="Gemeinsame Übung: Plank" />
              </div>
            </div>

            <h3 className="mt-6">
              Hier ein Überblick der Fitnessangebote im FREYRAUM:
            </h3>
            <div className="is-flex is-flex-wrap-wrap my-3">
              <span className="tag is-primary">Athletiktraining</span>
              <span className="tag is-primary">Functional Training</span>
              <span className="tag is-primary">Outdoorsport</span>
              <span className="tag is-primary">Crosstraining</span>
              <span className="tag is-primary">Yoga / Mobility</span>
              <span className="tag is-primary">Freie Trainingszeiten</span>
              <span className="tag is-primary">Gewichtheben</span>
              <span className="tag is-primary">Kettlebell</span>
              <span className="tag is-primary">Personaltraining</span>
              <span className="tag is-primary">Training für Sportmannschaften</span>
              <span className="tag is-primary">Laufgruppen</span>
              <span className="tag is-primary">Fit nach der Schwangerschaft</span>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Concept
