import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AppHeader, AppFooter } from './components'
import { Agb, Home, Impressum, NotFound } from './pages'
import './App.scss'

function App() {
  return (
      <div className="App">
        <AppHeader />
        <main>
          <Routes>
            <Route path="/app" element={<Navigate to="/app/" replace />}/>
            <Route path="/" element={ <Home/> }/>
            <Route path="/impressum" element={ <Impressum/> }/>
            <Route path="/agb" element={ <Agb/> }/>
            <Route path="*" element={ <NotFound/> }/>
          </Routes>
        </main>
        <AppFooter />
      </div>
  )
}

export default App
