import React from 'react'
import './NotFound.scss'

const NotFound: React.FC = () => {
  return (
      <div className="NotFound">
        <div className="container">
          <div className="big-404">
            404
          </div>
          <h1>Uppps, die Seite wurde nicht gefunden</h1>
          <p>
            Am besten machst Du jetzt 10 Burpees und gehst dann zurück zur Startseite:
          </p>
          <a href="/" className="button is-primary my-3">
            Zur Startseite
          </a>
          <p>
            Wenn Du meinst, dass hier ein Fehler vorliegt, kannst Du uns gerne eine Mail schreiben:
          </p>
          <a href="mailto:freyraum@freya.fitness" className="button my-3">
            Mail schreiben
          </a>
        </div>
      </div>
  )
}

export default NotFound
