
const BASE_URL = 'https://freya.fitness/api'
const securityHeaders = {
  'Access-Control-Allow-Credentials': 'true',
  'Access-Control-Allow-Origin': '*',
}

const acceptJsonHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

export const post = (url: string, body: any): Promise<Response> => request('POST', url, body)

const request = async (method: string, url: string, body?: any): Promise<Response> => {
  // TODO move auth to service
  // const AUTH = 'FREYRAUM/AUTH/'
  // const { value: accessToken } = await Storage.get({ key: AUTH + '/ACCESS_TOKEN' })

  return fetch(BASE_URL + url, {
    method,
    headers: {
      ...securityHeaders,
      ...acceptJsonHeaders,
      // 'Authorization': 'Bearer ' + accessToken,
    },
    body: !!body ? JSON.stringify(body) : undefined,
  })
}
