import React from 'react'
import './AppFooter.scss'

const AppFooter: React.FC = () => {

  return (
      <footer className="footer AppFooter">
        <div className="container">
          <div className="tile is-ancestor">
            <div className="tile is-parent is-3">
              <div className="tile is-child">
                <div className="is-flex is-flex-direction-column">
                  <span>Anschrift:</span>
                  <span>FREYRAUM</span>
                  <span>Huskoppel 3</span>
                  <span>21376 Salzhausen-Oelstorf</span>
                </div>
              </div>
            </div>

            <div className="tile is-parent is-3">
              <div className="tile is-child">
                <div className="is-flex is-flex-direction-column">
                  <span>Kontakt:</span>
                  <span>Telefon: <a href="tel:004915120712506" target="_blank" rel="noopener">+49 (0) 151 2071 2506</a></span>
                  <span>(derzeit nur via WhatsApp und E-Mail erreichbar)</span>
                  <span>E-Mail: <a href="mailto:freyraum@freya.fitness" target="_blank" rel="noopener"
                                   title="Kontaktiere uns via E-Mail (öffnet externes E-Mail-Programm)">
                  freyraum@freya.fitness
                </a></span>
                </div>
              </div>
            </div>

            <div className="tile is-parent is-6">
              <div className="tile is-child">
                <nav className="is-flex is-align-items-center is-justify-content-end"
                     aria-label="Weiterführende Seitenlinks">
                  <a className="navbar-item" href="/impressum">Impressum</a>
                  <a className="navbar-item" href="/agb">AGB</a>
                  <a className="" href="https://www.facebook.com/freyraum.fitness/" target="_blank" rel="noopener">
                    <span className="icon is-large">
                      <i className="fa-brands fa-facebook fa-2x" aria-hidden="true"/>
                    </span>
                  </a>
                  <a className="" href="https://www.instagram.com/freyraum.fitness/" target="_blank" rel="noopener">
                    <span className="icon is-large">
                      <i className="fa-brands fa-instagram fa-2x" aria-hidden="true"/>
                    </span>
                  </a>
                  <a className="" href="https://www.youtube.com/channel/UCPudg12FsBXS6xD-b-YVp6A/videos" target="_blank" rel="noopener">
                    <span className="icon is-large">
                      <i className="fa-brands fa-youtube fa-2x" aria-hidden="true"/>
                    </span>
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default AppFooter
