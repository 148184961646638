import React from 'react'
import './Impressum.scss'

const Impressum: React.FC = () => {
  return (
      <div className="Impressum">
        <div className="container">
          <h1 className="title is-1">Impressum</h1>

          <section className="my-6">
            <h2 className="title is-2">Betreiber</h2>
            <p>Freya Constanze Petersen</p>
            <p>Huskoppel 3</p>
            <p>21376 Salzhausen-Oelstorf</p>
            <p>Telefon: +49 (0) 151 2071 2506</p>
            <p>(derzeit nur via WhatsApp und E-Mail erreichbar)</p>
            <p>E-Mail: freyraum@freya.fitness</p>
          </section>

          <section className="my-6">
            <h2 className="title is-2">Haftung für Links</h2>
            <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
              Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
              Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
              Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
              jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
              Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
          </section>
        </div>
      </div>
  )
}

export default Impressum
